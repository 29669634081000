import { createTheme } from '@mui/material';
import getTheme from '../../js/themes/defaultTheme';

export const createPlatinumTheme = (mode) => {
  // Tenant Color Palette
  const tenantColors = {
    darkBlue: '#0069A6',
    lightBlue: '#ECF6FB',
    darkGray: '#353535',
    gray: '#424242',
    midGray: '#75787A',
    lightGray: '#AAAAAA',
    veryLightGray: '#F5F5F5',
    white: '#FFFFFF',
    charcoal: '#474747',
    paleGray: '#EAEBEB',
    skyBlue: '#6BC4E8',
    navy: '#163760',
    softGray: '#B8C1C8',
  };

  const defaultTheme = getTheme(mode);

  // Light Theme Overrides
  const lightOverrides = {
    palette: {
      ...defaultTheme.palette,
      primary: {
        main: tenantColors.darkBlue,
        dark: tenantColors.navy,
        light: tenantColors.lightBlue,
        contrastText: tenantColors.white,
      },
      secondary: {
        main: tenantColors.skyBlue,
        contrastText: tenantColors.navy,
      },
      accent: {
        main: tenantColors.skyBlue,
      },
      border: {
        main: tenantColors.paleGray,
      },
      separator: {
        main: tenantColors.lightGray,
      },
    },
  };

  // Dark Theme Overrides
  const darkOverrides = {
    palette: {
      ...defaultTheme.palette,
      primary: {
        main: tenantColors.darkGray,
        light: tenantColors.lightGray,
        contrastText: tenantColors.white,
      },
      secondary: {
        main: tenantColors.gray,
      },
      background: {
        default: tenantColors.darkGray,
        paper: tenantColors.charcoal,
      },
      border: {
        main: tenantColors.gray,
      },
      separator: {
        main: tenantColors.midGray,
      },
    },
    components: {
      ...defaultTheme.components,
      MuiTableHead: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-root': {
              backgroundColor: `${tenantColors.charcoal} !important`,
            },
          },
        },
      },
    },
  };

  return createTheme(mode === 'dark' ? darkOverrides : lightOverrides);
};

const themeConfig = (style) => createPlatinumTheme(style);

export default themeConfig;
